<template>
    <div>
        <p class="uk-text-bold">Other Salary Setup</p>
    </div>  
</template>

<script>
export default {
    name: 'OtherSalarySetup'
};
</script>
